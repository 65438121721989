import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import Logo from 'src/_components/Logo';
import Connect from 'src/_components/Connect';

const useStyles = makeStyles({
  header: {
    background: '#eaeaea !important',

  },
});

const Header = ({
  setWeb3,
  accounts,
  setAccounts,
  connected,
  setConnected,
  setShowError,
  setInvalidNetwork
}) => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Logo />
          </Typography>
          <Connect
          setWeb3={setWeb3}
          accounts={accounts}
          setAccounts={setAccounts}
          connected={connected}
          setConnected={setConnected}
          setShowError={setShowError}
          setInvalidNetwork={setInvalidNetwork}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
