import React, { useState } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Button from '@mui/material/Button';

import ConnectModal from './ConnectModal';

const Connect = ({
  setWeb3,
  accounts,
  setAccounts,
  connected,
  setConnected,
  setShowError,
  setInvalidNetwork
}) => {
  const [, setProvider] = useState(null);
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  const connectWallet = async (walletType) => {
    switch (walletType) {
      case 'metamask':
        connectMetamask();
        break;
      
      case 'walletConnect':
        connectWalletConnect();
        break;
    
      default:
        break;
    }
  };

  const connectMetamask = async () => {
    const providerLoc = await detectEthereumProvider();
    const chainId = await providerLoc.request({ method: 'net_version' });

    if (parseInt(chainId) !== parseInt(process.env.REACT_APP_PROVIDER_NETWORK)) {
      setInvalidNetwork(true);
      setShowError(true);
      setConnected(false);
      setAccounts([]);
      return false;
    }

    providerLoc.on('accountsChanged', connectMetamask);
    providerLoc.on('chainChanged', connectMetamask);
    providerLoc.on('disconnect', disconnectWeb3);

    setInvalidNetwork(false);
    setShowError(false);

    let isConnectedToWallet = await providerLoc.isConnected();

    if (isConnectedToWallet !== true) {
      disconnectWeb3();
      return false;
    }

    let accs = [];

    try {
      accs = await providerLoc.request({ method: 'eth_requestAccounts' });
    } catch (error) {
      console.log(error);
    }
    

    if (accs.length === 0) {
      // If wallet is connected
      try {
        accs = await providerLoc.request({ method: 'eth_accounts' });
      } catch (error) {
        console.log(error);
      }
    }
  
    if (accs === null || accs.length === 0) {
      disconnectWeb3();
      return false;
    }

    setAccounts(accs);
    setConnected(true);
    setShowConnectWallet(false);
      
    const web3Loc = new Web3(providerLoc);
    setWeb3(web3Loc);

    connectWeb3(providerLoc);
  };

  const connectWalletConnect = async () => {
    const providerLoc = new WalletConnectProvider({
      infuraId: '9aa3d95b3bc440fa88ea12eaa4456161',
      chainId: parseInt(process.env.REACT_APP_PROVIDER_NETWORK)
    });
    providerLoc.on('disconnect', disconnectWeb3);

    await providerLoc.enable().catch((err) => {
      return false;
    });

    if (providerLoc.accounts.length === 0) {
      return false;
    }

    setShowConnectWallet(false);
    
    setAccounts(providerLoc.accounts);

    connectWeb3(providerLoc);
  };

  const connectWeb3 = async (providerLoc) => {
    

    setProvider(providerLoc);

    if (!providerLoc) {
      alert('Please install Metamask');
      setConnected(false);
      return false;
    }
    
    setInvalidNetwork(false);
    setShowError(false);

    setShowConnectWallet(false);

    const web3Loc = new Web3(providerLoc);
    setWeb3(web3Loc);

    setConnected(true);
  };

  const disconnectWeb3 = () => {
    setConnected(false);
    setAccounts([]);
  }


  const addressToLabel = (address) => {
    const addressLength = address.length;

    let res = address.substring(0, 5) + '...' + address.substring(addressLength-4, addressLength);
    return res;
  };


  return (
    <>
    <ConnectModal
      showConnectWallet={showConnectWallet}
      setShowConnectWallet={setShowConnectWallet}
      connectWallet={connectWallet}
    />
    {connected !== true && <Button size="small" color="primary" variant="contained"
     onClick={() => {
      //connectWeb3();
      //requestConnection();
      setShowConnectWallet(true);
    }}
    >Connect Wallet</Button>}
    {connected === true && accounts !== null && <Button size="small" color="primary" variant="contained"
    onClick={() => {
      disconnectWeb3();
    }}
    >{addressToLabel(accounts[0])}</Button>}
    </>
  );
};

export default Connect;
