import React from 'react';

import {
  Modal,
  Grid,
  Box,
  Typography,
  Divider
} from '@mui/material';

import metamask from '../assets/images/metamask.svg';
import walletconnect from '../assets/images/walletconnect.svg';

const ConnectModal = ({
  showConnectWallet,
  setShowConnectWallet,
  connectWallet
}) => {
  const styleBoxGlobal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    
  };

  const styleBoxTop = {
    cursor: 'pointer',
    background: '#eaeaea !important',
    p: 2,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  };

  const styleBoxBottom = {
    cursor: 'pointer',
    background: '#eaeaea !important',
    p: 2,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  };

  const styleDivider = {
    background: '#ffc107 !important'
  };

  const styleIcons = {
    maxWidth: 50
  };


  return (
    <>
     <Modal
        open={showConnectWallet}
        onClose={() => setShowConnectWallet(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBoxGlobal}>
          <Box sx={styleBoxTop} onClick={() => {connectWallet('metamask')}}>
            <Grid
              container
              justifyContent="center"
            >
              <Grid item lg={6} md={8} sm={8} xs={8} justifyContent="center">
                <Typography
                  color="textPrimary"
                  variant="h6"
                >
                  Metamask
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Connect Metamask wallet
                </Typography>
              </Grid>
              <Grid item lg={6} md={2} sm={2} xs={2} align="center">
                <img src={metamask} alt="Metamask" style={styleIcons}/>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={styleDivider} />
          <Box sx={styleBoxBottom} onClick={() => {connectWallet('walletConnect')}}>
          <Grid
              container
              justifyContent="center"
            >
              <Grid item lg={6} md={8} sm={8} xs={8} justifyContent="center">
                <Typography
                  color="textPrimary"
                  variant="h6"
                >
                  WalletConnect
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Connect WalletConnect wallet
                </Typography>
              </Grid>
              <Grid item lg={6} md={2} sm={2} xs={2} align="center">
                <img src={walletconnect} alt="WalletConnect" style={styleIcons} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ConnectModal;
