import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import {
  Box,
  IconButton
} from '@mui/material';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import Header from './layouts/Header'
import IndexView from './views/index/IndexView';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffc107',
    },
    secondary: {
      main: '#212529',
    },
  },
});

function App() {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [connected, setConnected] = useState(false);

  const [showError, setShowError] = useState(false);
  const [invalidNetwork, setInvalidNetwork] = useState(false);
  
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header
        setWeb3={setWeb3}
        accounts={accounts}
        setAccounts={setAccounts}
        connected={connected}
        setConnected={setConnected}
        setShowError={setShowError}
        setInvalidNetwork={setInvalidNetwork}
        />

        {invalidNetwork === true && showError === true && <Box my={2}>
        <Alert severity="error" action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowError(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        >Please connect your wallet to {process.env.REACT_APP_PROVIDER_NETWORK_NAME}</Alert>
      </Box>}

        <IndexView 
        web3={web3}
        accounts={accounts}
        connected={connected}
        showError={showError}
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
