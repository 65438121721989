import React from 'react';

import {
  Box,
  Typography
} from '@mui/material';



const Info = () => {

  return (
    <>
      <Typography
          color="textPrimary"
          variant="h5"
        >
          How to Check Vested BSGG Tokens
        </Typography>
        <Box my={2}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            1) Install Metamask
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            Metamask will allow your browser to interact with Blockchain Smarts Contract. Metamask can be installed using Chrome, Brave, Firefox and Edge browsers as well as on iOS and Android. If you are using an iOS or Android device, you’ll need to utilize built-in Metamask browsers to enjoy the full functionality of Betswap Beta.
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            You can download Metamask from here <a href="https://metamask.io" target="_blank" rel="noreferrer">https://metamask.io</a>
          </Typography>
        </Box>

        <Box my={2}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Mobile Instructions
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            If you are using an iOS or Android device, you will need to access the built-in Browser within your MetaMask to enjoy the full functionality of Betswap Vesting.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            2) Check / Release BSGG Tokens
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            To check the vested BSGG tokens, you need to paste your account address and submit the form.
            If you've connected the same account in Metamask which you check for vested BSGG, you will have an option to release the tokens.
          </Typography>
        </Box>
    </>
  );
};


export default Info;
