import React, { useEffect, useState } from 'react';

import smartContractConf from "src/build/contracts/Vesting.json";

import tokenAbi from 'src/_data/tokentAbi.json'

import {
  Container,
  Box,
  Typography,
   Button
} from '@mui/material';

import Release from './Release';
import Info from './Info';

const IndexView = ({
  web3,
  accounts,
  connected,
  showError
}) => {
  const [smartContract, setSmartContract] = useState(null);
  const [address, setAddress] = useState(null);
  const [tokenInstance, setTokenInstance] = useState(null);

  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    initSmartContracts();
  }, [web3, accounts, connected]);

  
  const initSmartContracts = async () => {
    if (connected !== true || (accounts !== null && accounts.length === 0) || !web3) {
      return false;
    }

    const abi = smartContractConf.abi;
    const addressLocal = smartContractConf.networks[process.env.REACT_APP_PROVIDER_NETWORK].address;
    setAddress(addressLocal);

    const betSwap = new web3.eth.Contract(abi, addressLocal);
    setSmartContract(betSwap);

    const tokenI = new web3.eth.Contract(tokenAbi, process.env.REACT_APP_TOKEN_ADDRESS);
    setTokenInstance(tokenI);
  };
  

  return (
    <>
    <Container maxWidth={false}>
    {connected === true && accounts !== null && <>
      {accounts.length > 0 && <Box my={2}>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Connected Account: {accounts[0]}
        </Typography>
      </Box>}
       <Box my={2}>
        
          <Typography
            color="textPrimary"
            variant="body2"
          >
            Vesting Smart Contract: {smartContractConf.networks[process.env.REACT_APP_PROVIDER_NETWORK].address}
          </Typography>
        </Box>


        {web3 !== null && address !== null && <Release
        web3={web3}
        accounts={accounts}
        smartContract={smartContract}
        address={address}
        />}

        </>}
      {(accounts === null || accounts.length === 0) && <Box my={2}>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Please Connect Wallet to interact with Betswap Vesting Smart Contract.
        </Typography>
        <Box my={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setShowInfo(!showInfo);
            }}
            >
            Read More
          </Button>
        </Box>
      </Box>}
      {(accounts === null || accounts.length === 0) && showInfo === true && <Box my={4}>
        <Info />
      </Box>}
    </Container>
    </>
  );
};

export default IndexView;
